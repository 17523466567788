import React, { useRef } from 'react';

import '../styles/GallerySlider.scss';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import emilie1 from '../images/em-1.jpg';
import emilie2 from '../images/em-2.jpg';
import emilie3 from '../images/em-3.jpg';
import emilie4 from '../images/em-4.jpg';

const GallerySlider = ({ setSectionLoaded }) => {

  let imagesLoaded = useRef([]);

  const imagesToLoad = [
    emilie1,
    emilie2,
    emilie3,
    emilie4
  ];

  const imageLoaded = (e) => {
    
    if(!imagesLoaded.current.includes(e.target.src)) {
      imagesLoaded.current.push(e.target.src);
    }

    if(imagesLoaded.current.length >= imagesToLoad.length) {
      setSectionLoaded(true)
    }

  };

  const responsiveSettings = [
      {
          breakpoint: 1098,
          settings: {
              slidesToShow: 4,
              slidesToScroll: 1
          }
      },
      {
          breakpoint: 755,
          settings: {
              slidesToShow: 3,
              slidesToScroll: 1
          }
      },

      {
        breakpoint: 444,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1
        }
    },      
  ];

  return (
    <div className="gallery-slider-container">

        <Slide 
          slidesToScroll={1} 
          slidesToShow={1} 
          indicators={false}
          transitionDuration={3000}
          duration={0}
          arrows={false}
          easing='linear'
          responsive={responsiveSettings}
        >

          <img src={emilie1} onLoad={imageLoaded} onError={imageLoaded} alt='Picture of Emilie Rae drinking wine' />  
          <img src={emilie2} onLoad={imageLoaded} onError={imageLoaded} alt='Picture of Emilie Raelooking at wine bottle' />  
          <img src={emilie3} onLoad={imageLoaded} onError={imageLoaded} alt='Picture of Emilie Rae holding wine and eating grapes' />  
          <img src={emilie4} onLoad={imageLoaded} onError={imageLoaded} alt='Picture of Emilie Rae looking at camera with wine' />  
            
        </Slide>

    </div>
  );
}

export default GallerySlider;
