import React, { useEffect } from 'react'

import '../styles/Section2.scss';

import { gsap } from 'gsap';

import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Section2 = ({ setSectionLoaded }) => {

  const initScrollAnimation = () => {

    //gsap definitions
    const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".section-2-container",
          start: "top top",
          end: "+=25%",              
          pin: true,
          scrub: 1
        }
    });

    tl.to('.video-title', {autoAlpha: 1, y: '0%', duration: 1});
    
  };

  useEffect(() => {
      let ctx = gsap.context(() => {
        initScrollAnimation();
        setSectionLoaded(true);
      });
      
      return () => ctx.revert();
  }, []);  

  return (
    <div className="section-2-container">
        
        &nbsp;

    </div>
  );
}

export default Section2;
