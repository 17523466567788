import React from 'react';

import '../styles/Loader.scss';

const Loader = ({ }) => {

  return (
    <div className="loader-container">

      <p>Loading</p>

    </div>
  );
}

export default Loader;

