import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/globals.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from './pages/Home';
import FAQ from './pages/FAQ';
import Terms from './pages/Terms';
import SalesTerms from './pages/SalesTerms';
import Privacy from './pages/Privacy';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="faq" element={<FAQ />} />
        <Route path="terms" element={<Terms />} />
        <Route path="sales-terms" element={<SalesTerms />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
