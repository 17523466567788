import React, { useEffect } from 'react'

import '../styles/Footer.scss';

const Footer = ({ setSectionLoaded }) => {

  useEffect(() => {
    setSectionLoaded(true);
  }, []);

  return (
    <div className="footer">

          <a href='/terms' className='footer-button'>
            <p>Terms</p>
          </a>

          <a href='/privacy' className='footer-button'>
            <p>Privacy</p>
          </a>     

    </div>
  );
}

export default Footer;

