import React, { useRef } from 'react';

import { useLocation } from 'react-router-dom';

import '../styles/MainOverlay.scss';

import activeFaq from '../images/active-faq.svg';
import faq from '../images/faq.svg';

import onlyfans from '../images/onlyfans.svg';
import twitter from '../images/twitter.svg';
import insta from '../images/insta.svg';
import tiktok from '../images/tiktok.svg';

import activeOnlyfans from '../images/activeOnlyfans.svg';
import activeTwitter from '../images/activeTwitter.svg';
import activeInsta from '../images/activeInsta.svg';
import activeTiktok from '../images/activeTiktok.svg';

function MainOverlay( { setSectionLoaded }) {

  const location = useLocation();
  const { pathname } = location;
  let imagesLoaded = useRef([]);

  const imagesToLoad = [
    activeFaq,
    faq,
    onlyfans,
    twitter,
    insta,
    tiktok,
    activeOnlyfans,
    activeTwitter,
    activeInsta,
    activeTiktok
  ];

  const imageLoaded = (e) => {
    
    if(!imagesLoaded.current.includes(e.target.src)) {
      imagesLoaded.current.push(e.target.src);
    }

    if(imagesLoaded.current.length >= imagesToLoad.length) {

      if(setSectionLoaded)
        setSectionLoaded(true)
    }

  };

  return (
    <div className="main-overlay-container">
        
        <a href='/faq' className={`faq-button ${pathname === '/faq' ? 'active' : '' }`}>
          <img src={faq} onLoad={imageLoaded} onError={imageLoaded} alt='FAQ' />
          <img className='active' src={activeFaq} onLoad={imageLoaded} onError={imageLoaded} alt='FAQ' />
        </a>

        <a href='https://buy.stripe.com/dR68xR9gwdnRaLm288' target='_blank' className='preorder-button'>
          <p className='preorder-text'>BUY NOW</p>
        </a>        

        <div className='social-container'>
          <a href='https://onlyfans.com/emilieraex' className='social-button'>
            <img src={onlyfans} onLoad={imageLoaded} onError={imageLoaded} alt='OnlyFans Link' />
            <img className='active' src={activeOnlyfans} onLoad={imageLoaded} onError={imageLoaded} alt='OnlyFans Link' />
          </a>

          <a href='https://twitter.com/emilieraex' className='social-button'>
            <img src={twitter} onLoad={imageLoaded} onError={imageLoaded} alt='Twitter Link' />
            <img className='active' onLoad={imageLoaded} onError={imageLoaded} src={activeTwitter} alt='Twitter Link' />
          </a>          

          <a href='https://www.instagram.com/emilieraebux' className='social-button'>
            <img src={insta} onLoad={imageLoaded} onError={imageLoaded} alt='Instagram Link' />
            <img className='active' src={activeInsta} onLoad={imageLoaded} onError={imageLoaded} alt='Instagram Link' />
          </a>

          <a href='https://www.tiktok.com/@emilieraex' className='social-button'>
            <img src={tiktok} onLoad={imageLoaded} onError={imageLoaded} alt='TikTok Link' />
            <img className='active' onLoad={imageLoaded} onError={imageLoaded} src={activeTiktok} alt='TikTok Link' />
          </a>                              
        </div>

    </div>
  );
}

export default MainOverlay;
