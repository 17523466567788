import React, { useRef } from 'react';

import '../styles/Section5.scss';

import indulgeLeft from '../images/indulge-left.svg';
import indulgeRight from '../images/indulge-right.svg';
import leftButton from '../images/view-left.svg';
import rightButton from '../images/view-right.svg';
import circle from '../images/circle-2.svg';

const Section5 = ({ setSectionLoaded }) => {
  const leftFootRef = useRef(null); 
  const rightFootRef = useRef(null); 

  const leftHover = () => {
    leftFootRef.current.style = 'display: none';
    rightFootRef.current.style = 'display: initial';
  }

  const rightHover = () => {
    leftFootRef.current.style = 'display: initial';
    rightFootRef.current.style = 'display: none';
  }  

  let imagesLoaded = useRef([]);

  const imagesToLoad = [
    indulgeLeft,
    indulgeRight,
    leftButton,
    rightButton,
    circle
  ];

  const imageLoaded = (e) => {

    if(!imagesLoaded.current.includes(e.target.src)) {
      imagesLoaded.current.push(e.target.src);
    }

    if(imagesLoaded.current.length >= imagesToLoad.length) {    
      setSectionLoaded(true);
    }

  };  

  return (
    <div className="section-5-container">

      <div className="get-close-container">
        <img className='indulge-logo-left' ref={leftFootRef} src={indulgeLeft} onLoad={imageLoaded} onError={imageLoaded} alt='Indulge in the crush' />
        <img className='indulge-logo-right' ref={rightFootRef} src={indulgeRight} onLoad={imageLoaded} onError={imageLoaded} alt='Indulge in the crush' />

        <div className='button-container'>

          <a href='https://vimeo.com/915099022' target='_blank' className='left-button' onMouseOver={leftHover}>
            <img src={leftButton} onLoad={imageLoaded} onError={imageLoaded} alt='View of the left foot' />
            <img className='circle' src={circle} onLoad={imageLoaded} onError={imageLoaded} alt='drawn-circle' />
          </a>

          <a href='https://vimeo.com/915099234' target='_blank' className='right-button' onMouseOver={rightHover}>
            <img src={rightButton} onLoad={imageLoaded} onError={imageLoaded} alt='View of the right foot' />
            <img className='circle' src={circle} onLoad={imageLoaded} onError={imageLoaded} alt='drawn-circle' />
          </a>        

        </div>        
        
      </div>

      <p className='instructions'>
        See an exclusive POV video of the foot of your choice as it crushes the skins of the grapes, unleashing the sweet nectar within.
      </p>

    </div>
  );
}

export default Section5;

