import React from 'react'

import MainOverlay from "../components/main-overlay";

function SalesTerms() {

  return (
    <main>
     
     <MainOverlay />
           
      <div className='terms'>

        <h1>Terms of Sale</h1>
        
        <h3>Purchasing</h3>

        <p>All orders are fulfilled by Urban Initiatives Limited (“Renegade”, “we” or “us”). Products are offered subject to availability and remain the property of Renegade until payment is received in full. Any bank charges raised by the paying bank are payable by the customer and if incurred will be reinvoiced for immediate payment.</p>

        <p>We shall not be liable for any delay or failure to meet our obligations occasioned by circumstances beyond our reasonable control. </p>

        <p>You must be aged 18 years or older to place an order with us. All products delivered must be received by a person age 18 or older.</p>

        <p>None of these terms affect your consumer rights.</p>

        <div className='divider'></div>

        <h3>Shopping System</h3>

        <p>The Renegade online shopping system allows you to purchase wines and related merchandise for delivery to any UK address through our secure shopping system. All major credit and debit cards are accepted through Stripe which provides a high level of data encryption, ensuring secure transmission and processing of your details.</p>

        <p>You do not need to be registered on our website to order from us, and after placing an order online you will receive a confirmation by e-mail.</p>
        
        <h1>Right to Return / Cancel</h1>

        <p>We want you to be totally satisfied with every purchase you make from Renegade.</p>

        <p>However if you are a consumer and order products from us, you have statutory rights in the United Kingdom to cancel the order and return any goods ordered in exchange for a refund, provided such goods are in the same condition as supplied. This statutory right of cancellation starts on the date that you submit your order and expires within seven working days, beginning on the day after the day on which you receive the goods.</p>

        <p>Notification of intent to return/cancel goods must be received within 7 working days from your acceptance of delivery and also returned within 7 days of delivery. You are responsible for ensuring that the goods are received by us and are not damaged in transit. Goods should therefore be securely packaged. We recommend that you return items using recorded delivery for your protection. You are responsible for returning items to us - and covering costs thereof - unless items were incorrectly delivered through our fault. </p>

        <p>Notification of the return must be made in writing by email to [<a href='mailto:orders@renegadelondonwine.com'>orders@renegadelondonwine.com</a>]</p>

        <h3>Faulty Goods</h3>

        <p>If a wine is found to be corked or faulty we will be happy to offer a replacement or refund provided you contact us by telephone, post or email within three working days of delivery. You may be required to return the unfinished bottle for inspection, with cost of shipping the wine back to us refundable when the wine is assessed as faulty.</p>

        <h3>Incorrect Goods Delivered</h3>

        <p>In the unusual event that we delivery the wrong goods to you, we will offer you a refund or replacement of the incorrect wine, on return of the bottle(s) in question. Notification must be by telephone, post or email within three working days of delivery. The shipping costs of returning the bottle(s) to us will be refunded.</p>

        <p>In all the above cases please contact us with the details of items and quantities prior to arranging a return.</p>

        <div className='divider'></div>

        <h3>Wine prices and availability</h3>

        <p>All listed prices include VAT. Online prices may differ from in-store prices. Unless marked otherwise, listed prices of wine imported to the UK include all shipping costs to the UK and duty. Prices do not include the cost of delivery to the customer, which will be calculated and displayed in the process of placing an order.</p>

        <p>The online shop search function is intended to only show products that are currently in stock and available for despatch. On the rare occasion that a product appears as available but is not in stock, we will contact you to let you know when the product will next be in stock and/or recommend a similar alternative. You will also have the option of a full refund through Stripe.</p>

        <div className='divider'></div>

        <h3>Helpline for ordering online</h3>

        <p>If you have any questions about ordering online, please email <a href='mailto:orders@renegadelondonwine.com'>orders@renegadelondonwine.com</a>.</p>

        <div className='divider'></div>

        <h3>Intellectual property rights</h3>

        <p>All copyright, database rights, trademarks (including the Renegade® trademark) and any other intellectual property rights in and to the products, such as text, graphics, logos and images, is the sole property of Renegade or our licensor(s).</p>

        <p>Any use of the products (including copying, reproduction, duplication, distribution, or transmission, without the express written permission of Renegade and/or the applicable licensor) other than for ordering purposes, is strictly prohibited.</p>

        <h3>Drink Aware</h3>

        <p>Excessive consumption of alcoholic beverages can damage your health. Please drink responsibly, never drink and drive, and follow recommended guidelines for drinking, such as those found on the Drink Aware charity’s website at <a href='http://www.drinkaware.co.uk/tips-and-tools/drink-diary/'>http://www.drinkaware.co.uk/tips-and-tools/drink-diary/</a></p>
      </div>

    </main>
  );
}

export default SalesTerms;
