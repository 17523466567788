import React, { useRef, useEffect, useState } from 'react'

import VideoBackground from '../components/video-background';
import AgeGateOverlay from '../components/age-gate-overlay';
import MainOverlay from "../components/main-overlay";
import Section1 from "../components/section-1";
import Section2 from "../components/section-2";
import Section3 from "../components/section-3";
import Section4 from "../components/section-4";
import Section5 from "../components/section-5";
import Footer from '../components/footer';
import Loader from '../components/loader';

function Home() {
  const pageContainer = useRef(null); 
  const section1Ref = useRef(null);

  const [videoBackgroundLoaded, setVideoBackgroundLoaded] = useState(false);
  const [mainOverlayLoaded, setMainOverlayLoaded] = useState(false);
  const [section1Loaded, setSection1Loaded] = useState(false);
  const [section2Loaded, setSection2Loaded] = useState(false);
  const [section3Loaded, setSection3Loaded] = useState(false);
  const [section4Loaded, setSection4Loaded] = useState(false);
  const [section5Loaded, setSection5Loaded] = useState(false);
  const [footerLoaded, setFooterLoaded] = useState(false);
  const [ageGateLoaded, setAgeGateLoaded] = useState(false);  

  const [isLoading, setIsLoading] = useState(true);
  
  const unlockScroll = () => {
    pageContainer.current.style = {};
    section1Ref.current.startAnimation();
  };

  const hideLoader = () => {           
     setIsLoading(false);
   }
     
  const checkSectionsLoaded = () => {

    if(videoBackgroundLoaded && mainOverlayLoaded &&section1Loaded && section2Loaded && section3Loaded 
      && section4Loaded && section5Loaded && footerLoaded && ageGateLoaded) {

      return true;

    } else {
      
      return false;

    }

  };

  useEffect(() => {
    const hasLoaded = checkSectionsLoaded();

    if(hasLoaded) {            
      hideLoader();
    }

  }, [videoBackgroundLoaded, 
      mainOverlayLoaded,
      section1Loaded, 
      section2Loaded, 
      section3Loaded, 
      section4Loaded, 
      section5Loaded, 
      footerLoaded,
      ageGateLoaded]);  

  return (
    <main ref={pageContainer} style={{height: '0px', overflow: 'hidden'}}>
          
      <VideoBackground setSectionLoaded={setVideoBackgroundLoaded} />
      <MainOverlay setSectionLoaded={setMainOverlayLoaded} />
      <Section1 ref={section1Ref} setSectionLoaded={setSection1Loaded} />
      <Section2 setSectionLoaded={setSection2Loaded} />
      <Section3 setSectionLoaded={setSection3Loaded} />
      <Section4 setSectionLoaded={setSection4Loaded} />
      <Section5 setSectionLoaded={setSection5Loaded} />
      <Footer setSectionLoaded={setFooterLoaded} />
      
      <AgeGateOverlay setSectionLoaded={setAgeGateLoaded} unlockScroll={unlockScroll} />


      { isLoading && 
        <Loader />
      }

    </main>
  );
}

export default Home;
