import React, { useRef, useEffect, useState } from 'react'

import AgeGateOverlay from '../components/age-gate-overlay';
import MainOverlay from "../components/main-overlay";
import FAQContent from '../components/faq-content';
import Footer from '../components/footer';
import Loader from '../components/loader';

function FAQ() {
  const pageContainer = useRef(null); 

  const [section1Loaded, setSection1Loaded] = useState(false);
  const [mainOverlayLoaded, setMainOverlayLoaded] = useState(false);
  const [footerLoaded, setFooterLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);  

  const unlockScroll = () => {
    pageContainer.current.style = {};
  };

  const hideLoader = () => {
     setIsLoading(false);
   }
     
  const checkSectionsLoaded = () => {

    if(section1Loaded && mainOverlayLoaded && footerLoaded) {
      return true;
    } else {
      return false;
    }

  };

  useEffect(() => {
    const hasLoaded = checkSectionsLoaded();

    if(hasLoaded) {            
      hideLoader();
    }

  }, [section1Loaded, mainOverlayLoaded, footerLoaded]);  

  return (
    <main ref={pageContainer} style={{height: '0px', overflow: 'hidden'}}>
      <MainOverlay setSectionLoaded={setMainOverlayLoaded} />
      <FAQContent setSectionLoaded={setSection1Loaded} />
      <Footer setSectionLoaded={setFooterLoaded} />
      
      { isLoading && 
        <Loader />
      }

    </main>
  );
}

export default FAQ;
