import React, { useRef } from 'react'

import '../styles/FAQContent.scss';

import title from '../images/faq-title.svg';
import logo from '../images/logo.svg';
import subtitle from '../images/faq-subtitle.svg';

const FAQContent = ({ setSectionLoaded }) => {

  let imagesLoaded = useRef([]);

  const imagesToLoad = [
    title,
    logo,
    subtitle
  ];

  const imageLoaded = (e) => {
    
    if(!imagesLoaded.current.includes(e.target.src)) {
      imagesLoaded.current.push(e.target.src);
    }

    if(imagesLoaded.current.length >= imagesToLoad.length) {
      setSectionLoaded(true)
    }

  };

  return (
    <div className="faq-container">
        
        <img className='faq-title' src={title} onLoad={imageLoaded} onError={imageLoaded} alt='Simp Wine Logo with a foot stomping some grapes. Caberet Sauvignon' />

        <div className='text-container'>
          <img className='faq-subtitle' src={subtitle} onLoad={imageLoaded} onError={imageLoaded} alt='Frequently Asked Questions' />

          <p>
            Q: Have Emilie's Feet really been in every bottle? <br />
            A: Yeah.
          </p>

          <p>
            Q: Did you really use the grapes her feet crushed? <br />
            A: Yep.
          </p> 

          <p>
            Q: Is foot-crushed wine safe to dirnk? <br />
            A: Yes.
          </p> 

          <p>
            Q: Is this real? <br />
            A: It is real.
          </p> 

          <p>
            Q: Does Emilie really have perfect feet? <br />
            A: If you think so, it is true.
          </p> 
                                             
        </div>

        <a href='/' className='bottom-logo' ><img src={logo} onLoad={imageLoaded} onError={imageLoaded} alt='Simp Wine' /></a>

    </div>
  );
}

export default FAQContent;
