import React, { useRef, forwardRef, useImperativeHandle } from 'react'

import '../styles/Section1.scss';

import { gsap } from 'gsap';

import titleLeg from '../images/tall-logo-1.svg';
import leftLogo from '../images/tall-logo-2.svg';
import rightLogo from '../images/tall-logo-3.svg';

import sticker1 from '../images/sticker-1.svg';
import sticker2 from '../images/sticker-2.svg';
import sticker3 from '../images/sticker-3.svg';
import sticker4 from '../images/sticker-4.svg';
import sticker5 from '../images/sticker-5.svg';

const Section1 = forwardRef(({ setSectionLoaded }, ref) => {

  let imagesLoaded = useRef([]);

  const imagesToLoad = [
    titleLeg,
    leftLogo,
    rightLogo,
    sticker1,
    sticker2,
    sticker3,
    sticker4,
    sticker5,
  ];

  const imageLoaded = (e) => {
    
    if(!imagesLoaded.current.includes(e.target.src)) {
      imagesLoaded.current.push(e.target.src);
    }

    if(imagesLoaded.current.length >= imagesToLoad.length) {
      setSectionLoaded(true)
    }

  };

  useImperativeHandle(ref, () => ({
      startAnimation,        
  }));

  const startAnimation = () => {

    //gsap definitions
    const tl = gsap.timeline({});

    tl.addLabel('animation-start');

    tl.to('.title-leg', {y: '11.5%', duration: 2}, 'animation-start');

      tl.to('.title-left', {x: '-64%', duration: 1.5}, 'animation-start');
      
      tl.to('.title-right', {x: '71%', duration: 1.5}, 'animation-start'); 

    tl.addLabel('animation-end');

    tl.to('.sticker-1', {scale: 0.8, autoAlpha: 1, duration: 0.2, delay: 20}, 'animation-end')
    .to('.sticker-2', {scale: 0.8, autoAlpha: 1, duration: 0.2, delay: 0.4})
    .to('.sticker-3', {scale: 0.8, autoAlpha: 1, duration: 0.2, delay: 0.4})
    .to('.sticker-4', {scale: 0.8, autoAlpha: 1, duration: 0.2, delay: 0.4})
    .to('.sticker-5', {scale: 0.8, autoAlpha: 1, duration: 0.2, delay: 0.4})

    
  };

  return (
    <div className="section-1-container">
                
        <img className='title-leg' src={titleLeg} onLoad={imageLoaded} onError={imageLoaded} alt='foot stomping some grapes' />
        <img className='title-left' src={leftLogo} onLoad={imageLoaded} onError={imageLoaded} alt='Simp Caberet Sauvignon' />
        <img className='title-right' src={rightLogo} onLoad={imageLoaded} onError={imageLoaded} alt='Wine. Grapes pressed by the feet of Emilie Rae' />

        <img className='sticker sticker-1' src={sticker1} onLoad={imageLoaded} onError={imageLoaded} alt='sticker1' />
        <img className='sticker sticker-2' src={sticker2} onLoad={imageLoaded} onError={imageLoaded} alt='sticker2' />
        <img className='sticker sticker-3' src={sticker3} onLoad={imageLoaded} onError={imageLoaded} alt='sticker3' />
        <img className='sticker sticker-4' src={sticker4} onLoad={imageLoaded} onError={imageLoaded} alt='sticker4' />
        <img className='sticker sticker-5' src={sticker5} onLoad={imageLoaded} onError={imageLoaded} alt='sticker5' />

    </div>
  );
});

export default Section1;
