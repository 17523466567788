import React, { useRef } from 'react';

import '../styles/AgeGateOverlay.scss';

import logo from '../images/logo.svg';
import confirmButton from '../images/confirm.svg';
import denyButton from '../images/deny.svg';
import circle from '../images/circle.svg';

import { gsap } from 'gsap';

function AgeGateOverlay({setSectionLoaded, unlockScroll}) {

  let imagesLoaded = useRef([]);

  const imagesToLoad = [
    logo,
    confirmButton, 
    denyButton, 
    circle
  ];

  const imageLoaded = (e) => {
    
    if(!imagesLoaded.current.includes(e.target.src)) {
      imagesLoaded.current.push(e.target.src);
    }

    if(imagesLoaded.current.length >= imagesToLoad.length) {
      setSectionLoaded(true)
    }

  };

  const confirmClicked = () => {
    gsap.to('.age-gate-overlay-container', {opacity: 0, duration: 1, onComplete: () => {
      gsap.to('.age-gate-overlay-container', {height: 0, width: 0});

      unlockScroll();
    }});
  
  };

  const denyClicked = () => {
    window.open('https://www.drinkaware.co.uk/advice-and-support/underage-drinking');
  };


  return (
    <div className="age-gate-overlay-container">
        
        <div className='content-container'>

          <img className='logo' src={logo} onLoad={imageLoaded} onError={imageLoaded} alt='Simp Wine' />

          <p>Please confirm that you are a simp over the age of 18 to view this page.</p>

          <div className='button-container'>

            <button className='confirm-button' onClick={confirmClicked}>
              <img src={confirmButton} onLoad={imageLoaded} onError={imageLoaded} alt='Confirm' />
              <img className='circle' src={circle} onLoad={imageLoaded} onError={imageLoaded} alt='drawn-circle' />
            </button>

            <button className='deny-button' onClick={denyClicked}>
              <img src={denyButton} onLoad={imageLoaded} onError={imageLoaded} alt='Deny' />
              <img className='circle' src={circle} onLoad={imageLoaded} onError={imageLoaded} alt='drawn-circle' />
            </button>        

          </div>          

        </div>
    </div>
  );
}

export default AgeGateOverlay;
