import React, { useEffect, useState, useRef } from 'react'

import '../styles/Section4.scss';

import signature from '../images/signature.svg';

import GallerySlider from './gallery-slider';


const Section4 = ({ setSectionLoaded }) => {
  const [galleryLoaded, setGalleryLoaded] = useState(false);
  const [sectionImagesLoaded, setSectionImagesLoaded] = useState(false);
  
  let imagesLoaded = useRef([]);

  const imagesToLoad = [
    signature
  ];

  const imageLoaded = (e) => {
    
    if(!imagesLoaded.current.includes(e.target.src)) {
      imagesLoaded.current.push(e.target.src);
    }

    if(imagesLoaded.current.length >= imagesToLoad.length) {
      setSectionImagesLoaded(true)
    }

  };

  const checkSectionsLoaded = () => {

    if(sectionImagesLoaded && galleryLoaded) {
      return true;
    } else {
      return false;
    }

  };


  useEffect(() => {
    const hasLoaded = checkSectionsLoaded();

    if(hasLoaded) {            
      setSectionLoaded(true);
    }

  }, [galleryLoaded, sectionImagesLoaded]);  

  return (
    <div className="section-4-container">
      
      <img className='signature' src={signature} onLoad={imageLoaded} onError={imageLoaded} alt='signature' />

      <p>Emilie Rae has been described as having perfect feet. For her simps, this is gospel. She has built a loyal following of fans who appreciate her for her authenticity and innovation in the space, garnering hundreds of thousands of devotees. Although she is a star in the foot fetish community, her influence goes beyond her exquisite arches.</p>

      <GallerySlider setSectionLoaded={setGalleryLoaded} />

    </div>
  );
}

export default Section4;
