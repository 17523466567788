import React, { useEffect, useRef } from 'react'

import '../styles/Section3.scss';

import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";

import { gsap } from 'gsap';

import { ScrollTrigger } from 'gsap/ScrollTrigger';

import bottle from '../images/wine-bottle.png';
import price from '../images/price.svg';
import logo from '../images/outline-logo.svg';

import grape from '../images/grape.png';
import grape2 from '../images/grape-2.png';
import grape3 from '../images/grape-3.png';
import grape4 from '../images/grape-4.png';
import grape5 from '../images/grape-5.png';

gsap.registerPlugin(ScrollTrigger);

const Section3 = ({ setSectionLoaded }) => {

  let imagesLoaded = useRef([]);

  const imagesToLoad = [
    bottle, 
    price, 
    logo,
    grape,
    grape2,
    grape3,
    grape4,
    grape5
  ];

  const imageLoaded = (e) => {

    if(!imagesLoaded.current.includes(e.target.src)) {
      imagesLoaded.current.push(e.target.src);
    }

    if(imagesLoaded.current.length >= imagesToLoad.length) {
      setSectionLoaded(true)
    }

  };

  const initScrollAnimation = () => {

    //gsap definitions
    const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.section-3-container',
          toggleActions: 'play none none none',
          start: 'top 50%',                      
          pin: false,          
        }
    });
    
    tl.addLabel('animation-start');

    tl.to('.wine-container', { scale: 1, duration: 1.5}, 'animation-start');   

  };

  useEffect(() => {
      let ctx = gsap.context(() => {
        initScrollAnimation();
      });
      
      return () => ctx.revert();
  }, []);  

  return (
    <MouseParallaxContainer className='section-3-container' globalFactorX={0.1} globalFactorY={0.1}>
      
      <div className='left-container'>

        <div className='wine-container'>
          <div className='bottle'>
            <img src={bottle} onLoad={imageLoaded} onError={imageLoaded} alt='Bottle of Simp Wine' />
          </div>
          <MouseParallaxChild className='grape-1' factorX={0.5} factorY={0.1}>
            <img src={grape} onLoad={imageLoaded} onError={imageLoaded} alt='Grapes' />
          </MouseParallaxChild>
          <MouseParallaxChild className='grape-2' factorX={-0.3} factorY={0.1}>
            <img src={grape2} onLoad={imageLoaded} onError={imageLoaded} alt='Grapes' />
          </MouseParallaxChild>
          <MouseParallaxChild className='grape-3' factorX={0.4} factorY={0.1}>
            <img src={grape3} onLoad={imageLoaded} onError={imageLoaded} alt='Grapes' />
          </MouseParallaxChild>
          <MouseParallaxChild className='grape-4' factorX={-0.3} factorY={0.1}>
            <img src={grape4} onLoad={imageLoaded} onError={imageLoaded} alt='Grapes' />
          </MouseParallaxChild>
          <MouseParallaxChild className='grape-5' factorX={-0.3} factorY={0.1}>
            <img src={grape5} onLoad={imageLoaded} onError={imageLoaded} alt='Grapes' />
          </MouseParallaxChild>
        </div>

      </div>

      <div className='right-container'>

        <img className='logo' src={logo} onLoad={imageLoaded} onError={imageLoaded} alt='Simp Wine Logo' />        

        <p>This is wine crafted with grapes pressed by the ‘perfect feet’ of Emilie Rae, the very feet that simps devote themselves to. This is their dedication, manifest in liquid luxury.</p>

        <p>This wine represents a refinement of simpism, one without prejudice, instead emboldened with class. Made to elevate the perception of devotion to something more astute.</p>                

        <img className='price' src={price} onLoad={imageLoaded} onError={imageLoaded} alt='£100' />
        
        <a href='https://buy.stripe.com/dR68xR9gwdnRaLm288' target='_blank' className='preorder-button'>
          <p className='preorder-text'>BUY NOW</p>
        </a>               

      </div>
    </MouseParallaxContainer>
  );
}

export default Section3;
