import React, { useRef } from 'react'

import '../styles/VideoBackground.scss';

import verticalVideo from '../videos/background-loop.mp4';

const VideoBackground = ({ setSectionLoaded }) => {

  const videoRef = useRef(null);  

  const insertVideo = () => {
      const videoSrc = window.innerWidth < 500 ? verticalVideo : verticalVideo;
      const classString = window.innerWidth < 500 ? 'vertical-video' : 'horizontal-video';

      return (
          <video ref={videoRef} id='video-background' className={classString} src={videoSrc} muted onCanPlayThrough={videoInit} playsInline autoPlay={true} loop={true}></video>
      )
  };

  const videoInit = async () => {
      setSectionLoaded(true);

      try {
          await videoRef.current.play();
      } catch(e) {

      }
  };  

  return (
    <div className="video-background-container">
        { insertVideo() }

        <h1 className="video-title">Quench your thirst<br />with the fruit of<br />your desires</h1>
        
    </div>
  );
}

export default VideoBackground;
